import React from "react";
import s from './Loading.module.scss';

const Loading = () => {

    return(
        <div className={s.loading}>
            <div>Загрузка ...</div>
        </div>
    )
}

export default Loading;