import React,  { useState, useEffect, useContext } from "react";
import s from './PageCase.module.scss';
import CaseOpener from "../CaseOpener/CaseOpener";
import { SocketContext } from "../../../services/socket";
import ElementImg from "./ElementImg/ElementImg";
import InfoDrop from "./InfoDrop/InfoDrop";


const PageCase = ({ id_case , quality, updateStateScore}) => {
    
    const socket = useContext(SocketContext); 
    const [drops, setDrops] =  useState([])
    const [infoDrop, setInfoDrop] = useState(null)

    useEffect(() => {
        const interval = setInterval(() => {
            if (socket) {
                clearInterval(interval);
                socket.emit('getDropsCase',id_case)
            }
        },100)
  
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        } 
    },[socket]);

    useEffect(()=> {
        if (socket) {
            socket.on('dropsCase', (data) => {
                setDrops(data)
            })
        }
      })

    const closeModal = () => {
        setInfoDrop(null)
    }
    const openInfoModal = (item) => {
        setInfoDrop(item)
    }

    let imgCase 
    if (quality == 'common') {imgCase = s.imgCommon}
    if (quality == 'uncommon') {imgCase = s.imgUncommon} 
    if (quality == 'rare') {imgCase = s.imgRare} 
    if (quality == 'epic') {imgCase = s.imgEpic} 
    if (quality == 'legend') {imgCase = s.imgLegend} 

    return(
        <div className={s.pageCase}>
        
            <div className={imgCase}></div>

            <CaseOpener price_base = {drops[0]?.price_base} price_pin = {drops[0]?.price_pin} id_case={id_case} updateStateScore={updateStateScore}/>
            
            <div className={s.headerItems}>Из сейфа можно получить следующие предметы:</div>
            {infoDrop != null && <InfoDrop itemDrop={drops[infoDrop]} closeModal={closeModal} />}

            <div className={s.items}> 
    
            {
                drops.map((d) => {

                    let qualityElement
                        if (d.quality == 'common') {qualityElement = s.itemCommon}
                        if (d.quality == 'uncommon') {qualityElement = s.itemUncommon} 
                        if (d.quality == 'rare') {qualityElement = s.itemRare} 
                        if (d.quality == 'epic') {qualityElement = s.itemEpic} 
                        if (d.quality == 'legend') {qualityElement = s.itemLegendary} 

                    return (
                        <div className={qualityElement} onClick={()=>openInfoModal(d.id)}>
                            <ElementImg imagePath={d.img} />
                        </div>
                    )
                })
            }
           
         </div>
        </div>
    )
}

export default PageCase;

