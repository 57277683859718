import React , { useEffect, useState , useContext, useRef, useCallback } from "react";
import s from './Address.module.scss';

const Address = () => {
      
    return(
     <div className={s.address}>
            <div className={s.addressHeader}>
                Контактные данные
            </div>
            <div className={s.taskDesk}>Укажите необходимую информацию, чтобы мы могли доставить выйгрыш.
               Если будут вопросы с Вами свяжется представитель поддержки PigPoke :)
               </div>
               
            <div className={s.indent}></div>
            <div className={s.field}>
                
                <input className={s.input} type="text" name="lastname" id="lastname" />
                <label className={s.label} for="lastname">Фамилия</label>
            </div>
            <div className={s.field}>
                
                <input className={s.input} type="text" name="firstname" id="firstname" />
                <label className={s.label} for="firstname">Имя</label>
            </div>
            <div className={s.field}>
                
                <input className={s.input} type="text" name="surname" id="surname" />
                <label className={s.label} for="surname">Отчество</label>
            </div>
            <div className={s.field}>
                
                <input className={s.input} type="text" name="address" id="address" />
                <label className={s.label} for="address">Адрес получателя</label>
            </div>
            <div className={s.field}>
                
                <input className={s.input} type="text" name="indx" id="indx" />
                <label className={s.label} for="indx">Почтовый индекс</label>
            </div>
            <div className={s.field}>
                
                <input className={s.input} type="text" name="phone" id="phone" />
                <label className={s.label} for="phone">Номер телефона</label>
            </div>
            <div className={s.save}>
                Сохранить
            </div>
      </div> 
    )
}

export default Address;



