import React, { createContext, useState, useEffect } from "react";
import io from "socket.io-client";
import { useTelegram } from "../hoc/useTelegram";
import Loading from "./Loading/Loading";

const SocketContext = createContext(null);

const SocketProvider = ({ children }) => {

  const { tg } = useTelegram();

  const [socket, setSocket] = useState(null);
  const [userId, setUserId] = useState(null)

  useEffect(() => {
    const interval = setInterval(() => {
            if (tg.initDataUnsafe.user?.id) {
              setUserId(tg.initDataUnsafe.user?.id)
              clearInterval(interval);
            }
    },5000)

    return () => {
        if (interval) {
            clearInterval(interval);
        }
    } 
},[]);

  useEffect(() => {
    if (userId) {
      const newSocket = io("https://pokestore.ru:3001",{
        query: {
          userId: tg.initDataUnsafe.user?.id
          , username: tg.initDataUnsafe.user?.username
          , first_name: tg.initDataUnsafe.user?.first_name
          , last_name: tg.initDataUnsafe.user?.last_name
          , ref_link: tg.initDataUnsafe?.start_param
          , initData: tg?.initData
      }
    });
    setSocket(newSocket);

    return () => newSocket.disconnect(); // Закройте соединение при отмонтировании
    }
   
  }, [userId]);


  const main = userId ? children : <Loading />
  return (
    <SocketContext.Provider value={socket}>
      {main}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };
