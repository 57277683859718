import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

function DropImg(props) {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const imgPath = props.item.img; 
    const img = require(`../../PageCase/img/${imgPath}`);
    setImageUrl(img);
  }, [props.item]);

  const itemVariants = {
    initial: { x: 2950, opacity: 0.8 },
    animate: (i) => ({
      x: -1880,
      opacity: 1,
      transition: {
        delay: i * 0.1, // Увеличили задержку для замедления
        duration: 10,     // Увеличили длительность анимации
        ease: [0.2, 0.8, 0.6, 1]
      },
    }),
   
  };

  let shadow 
    if (props.item.quality == 'common') {shadow = '0.2vh 0.4vh 0.6vh 0.2vh rgba(148, 151, 153, 0.21)'}
    if (props.item.quality == 'uncommon') {shadow = '0.2vh 0.4vh 0.6vh 0.2vh rgba(23, 235, 34, 0.21)'} 
    if (props.item.quality == 'rare') {shadow = '0.2vh 0.4vh 0.6vh 0.2vh rgba(50, 105, 255, 0.21)'} 
    if (props.item.quality == 'epic') {shadow = '0.2vh 0.4vh 0.6vh 0.2vh rgba(163, 27, 241, 0.21)'} 
    if (props.item.quality == 'legend') {shadow = '0.2vh 0.4vh 0.6vh 0.2vh rgba(180, 192, 15, 0.21)'} 

  return (
    <motion.div
    key={props.item.id}
    variants={itemVariants}
    style={{
      margin: '0 10px',
      backgroundImage: `url(${imageUrl})`, //`../PageCase/img/${item}`,
      backgroundSize: '155px 117px',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      padding: '40px',
      maxHeight: '40px',
      maxWidth: '70px',
      height: '40px',
      width: '70px',
      borderRadius: '1vh',
      color: 'transparent',
      boxShadow: shadow
    }}
  >
  Изображение {props.item.id}
  </motion.div>
  );
}
export default DropImg;