import React,  { useState, useEffect, useContext } from "react";
import s from './InfoModal.module.scss';


const InfoModal = ({info, closeInfoModal}) => {

    
   
    return(
        <div className={s.dropModal}>
           <div className={s.container}>
                <div className={s.header}>{info}</div>
                <div className={s.button} onClick={() => closeInfoModal()}>
                    Ок
                </div>
           </div>
        </div>
    )
}

export default InfoModal;