import React ,  { useState, useEffect, useContext } from "react";
import s from './CommonTask.module.scss';
import { SocketContext } from "../../../services/socket";
import { useDispatch, useSelector } from "react-redux";
import { updateFreePoke } from "../../../store/reducers/counterReducers";

const CommonTask = () => {

    const socket = useContext(SocketContext);
    const dispatch = useDispatch(); 

    const [tasks, setTasks] = useState([])

    const [timeLeft, setTimeLeft] = useState(null);
    const next_poke = useSelector((state) => state.counter.next_poke); 

    const handleIconClick = (iconId) => {
        if (socket) {
            socket.emit('freePoke', iconId)
        }
    };

    useEffect(()=> {

        if (socket) {

            socket.on('resFreePoke', (data) => {
                const payload = {
                    common: data.common,
                    next_poke: data.next_poke,
                    free_poke: data.free_poke
                }
                dispatch(updateFreePoke(payload));
            })
        }
        return () => {
            if (socket) {
             socket.off('resFreePoke');
            }
           };
    },[])

    useEffect(() => {
        const targetDate = new Date(next_poke).getTime(); // Преобразуем в миллисекунды
        let interval; 
        // Функция для обновления оставшегося времени
        const updateCountdown = () => {
            const now = new Date().getTime(); // Текущее время в миллисекундах
            const difference = targetDate - now; // Разница между целевой датой и текущим временем

            if (difference <= 0) {
                setTimeLeft(0); // Устанавливаем 0, если время истекло
                clearInterval(interval); // Очищаем интервал, так как отсчет завершен
            } else {
                setTimeLeft(Math.floor(difference / 1000)); // Устанавливаем оставшееся время в секундах
            }
        };

        updateCountdown(); // Первая инициализация
        interval = setInterval(updateCountdown, 1000); // Обновление каждую секунду

        // Очистка интервала при размонтировании компонента
        return () => clearInterval(interval);
    }, [next_poke]); // В зависимости от целевой даты

    const hoursLeft = Math.floor(timeLeft / 3600);
    const minutesLeft = Math.floor((timeLeft % 3600) / 60);
    const secondsLeft = timeLeft % 60;
    let contentTime 
    if (timeLeft == 0 ) {
        contentTime =<div className={s.execute} onClick={() => handleIconClick()}>Выполнить</div>
    } else {
        contentTime = <div className={s.executeEnd}>{hoursLeft.toString().padStart(2, '0')}:{minutesLeft.toString().padStart(2, '0')}:{secondsLeft.toString().padStart(2, '0')}</div>
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (socket) {
                clearInterval(interval);
                socket.emit('getListCommonTask')
            }
        },100)
  
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        } 
    },[socket]);

    useEffect(()=> {
        if (socket) {
            socket.on('listCommonTask', (data) => {
                setTasks(data)
            })
        }
      })

      useEffect(() => {
        if (socket) {
            socket.on('resCheckSubChannel',(data) => {
                if (data.sub == true) {
                    socket.emit('progressAwardSubChannel', data.task_id)
                } else {
                    window.open(data.link, '_blank', 'noopener,noreferrer');
                }
            })
        }
        return () => {
            if (socket) {
             socket.off('resCheckSubChannel');
            }
           };
      },[])


    const clickCheckSubChannel = (id_channel, task_id,link) => {
        if (socket) {
            socket.emit('checkSubChannel',id_channel,task_id,link)
        }
    };


    return(
        <div className={s.commonTask}>
            <div className={s.task}>
                <div className={s.desc}>
                    <div className={s.name}>
                        Заходи в PigPoke каждые 3 часа 
                    </div>
                    <div className={s.award}>
                        Обычный мешочек х1
                    </div>
                </div>
                {contentTime}
            </div>
            {
                tasks.map((task) => {

                    const button1 = task.status == 'subscribe' ? <div className={s.executeEnd}>Выполнено</div>
                    :<div className={s.execute} onClick={() => clickCheckSubChannel(task.id_channel,task.id,task.link)}>Выполнить</div>
                   // const button2 = <div className={s.execute} onClick={() => openWebLink(task.link,task.id_channel, task.id)}>Выполнить</div>

                    return (
                        <div className={s.task}>
                                        <div className={s.desc}>
                                            <div className={s.name}>
                                                {task.name_task} 
                                            </div>
                                            <div className={s.award}>
                                                {task.desc_task}
                                            </div>
                                        </div>
                                        {button1}
                                    </div>
                    )
                })
            }
             
        </div>
    )
}

export default CommonTask;