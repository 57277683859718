import React from "react";
import s from './Header.module.scss';
import {useSelector} from 'react-redux';


const Header = () => {

  const count = new Intl.NumberFormat('ru-RU').format(useSelector((state) => state.counter.score)) ;

  const username = useSelector((state) => state.counter.username);
    return(
        <div className={s.header}>

            <div className={s.top}>
                    <div className={s.beta}>PigPoke Beta</div>
                    <div className={s.user}>{username}</div>
            </div>
            <div className={s.imgCoin}></div>
            <div className={s.headerBottom}>
                <div className={s.bank_score}>{count} <span className={s.pig}>$PIG</span> </div>
            </div>
      
        </div>
    )
}

export default Header;