import React from "react";
import s from './Content.module.scss'
import BlockGame from "./BlockGame/BlockGame";

import {  Routes, Route } from 'react-router-dom';

const Content = ({chooseTypeBoxes}) => {


    const openTelegramChannel = () => {
        window.open('https://t.me/PigPokeNews', '_blank', 'noopener,noreferrer');
    }

    return(
        <div className={s.content}>

            <div className={s.headerContent}>
                События
            </div>

            <div className={s.events}>
                    <div className={s.event}>
                            <div className={s.nameEvent}>Открывай мешочки и получай $PIG</div>
                            <div className={s.descEvent}>Чтобы получить больше мешочков заходи каждый день, выполняй задания и приглашай друзей.</div>
                            <BlockGame chooseTypeBoxes={chooseTypeBoxes}/>
                    </div>
                    <div className={s.event} onClick={()=>openTelegramChannel()}>
                            <div className={s.nameEvent}>Приветствуем тебя в PigPoke! 👋</div>
                            <div className={s.descEvent}>Узнать больше о проекте в Telegram канале.</div>

                            <div className={s.openLink} >
                            
                            </div>
                           
                    </div>
            </div>
        
            
             
        </div>
    )
}

export default Content;