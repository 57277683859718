import React,  { useState, useEffect, useContext } from "react";
import s from './ModalBoxes.module.scss';
import {useDispatch, useSelector} from 'react-redux';
import { SocketContext } from "../../../services/socket";
import { incrementCounter, incrementCounterClick } from "../../../store/reducers/counterReducers";
import Drop from "../../Tasks/Drop/Drop";

const ModalBoxes = ({type, chooseTypeBoxes}) => {

    const countPoke =[
        useSelector((state) => state.counter.common),
        useSelector((state) => state.counter.uncommon),
        useSelector((state) => state.counter.rare),
        useSelector((state) => state.counter.epic),
        useSelector((state) => state.counter.legendary)
    ] 

    const socket = useContext(SocketContext);
    const [isVisibleDrop,setVisibleDrop] = useState(0)
    const [isVisibleDrop2,setVisibleDrop2] = useState(0)
    const [activeIconClick,setActiveIconClick] = useState(0)
    const incrementValue = useSelector((state) => state.counter.incrementValue);

    const dispatch = useDispatch();    
    const [icons, setIcons] = useState([
        {   id: 1, isActive: false, value: 0 },
        {   id: 2, isActive: false, value: 0 },
        {   id: 3, isActive: false, value: 0 }
    ]);

    useEffect(()=> {

        if (socket) {
            socket.on('resultClick', (game_data) => {
                setIcons(icons.map((icon) => 
                    icon.id === game_data.activeIcon ? {
                        ...icon,
                        isActive: true,
                        value: game_data.value
                    } : {...icon, isActive: false, value: 0}
                ));
                const payload = {
                    counter: game_data.counter,
                    value: game_data.isTrue === true ? game_data.value : 0,
                    common: game_data.common,
                    uncommon: game_data.uncommon,
                    rare: game_data.rare,
                    epic: game_data.epic,
                    legendary: game_data.legendary
                }
                dispatch(incrementCounter(payload));
            })

        }
        return () => {
            if (socket) {
             socket.off('resultClick');
            }
           };
    },[])
    const handleIconClick = (iconId) => {
        socket.emit('iconClick', iconId, type)
        setActiveIconClick(iconId)
        const payload = {value:  0}
        dispatch(incrementCounterClick(payload))
        if (isVisibleDrop == iconId) {
            setVisibleDrop(0)
            setVisibleDrop2(iconId)
        } else {
            setVisibleDrop2(0)
            setVisibleDrop(iconId)
        } 
    };

    const style_box = [s.img_0,s.img_1,s.img_2,s.img_3,s.img_4]

    return(
        <div className={s.modal}>
           <div className={s.container}>
            <div className={s.totalField}>Осталось: <span className={s.total}>{countPoke[type]}</span></div>
             <div className={s.header}>Выбери мешочек</div>
             <div className={s.desc}>В одном из них находятся $PIG</div>
            <div className={s.boxes}>
           
            {icons.map((icon) => {
                
                return(
                <div key={icon.id} className={icon.id === activeIconClick ? s.boxActive : s.box} 
                    onClick={() => handleIconClick(icon.id)}
                >
                   <div className={style_box[type]}></div> <div className={icon.isActive === false ? s.sumAwardFalse : s.sumAwardTrue}>{icon.value}<span> $PIG</span></div>
                   {isVisibleDrop != isVisibleDrop2 && isVisibleDrop == icon.id && incrementValue > 0 ? <Drop /> : null}
                   {isVisibleDrop != isVisibleDrop2 && isVisibleDrop2 == icon.id && incrementValue > 0 ? <Drop /> : null}
                </div>
             ) })
             }
        </div>

             <div onClick={()=>chooseTypeBoxes(null)} className={s.button}>Закрыть</div>
           </div>
        </div>
    )
}

export default ModalBoxes;