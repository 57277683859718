import React,  { useEffect, useState , useContext } from "react";
import s from './Home.module.scss';
import Header from "./Header/Header";
import Content from "./Content/Content";
import ModalBoxes from "./ModalBoxes/ModalBoxes";

const Home = () => {

  const [type, setType] = useState(null);

  const chooseTypeBoxes = (button_id) => {
    setType(type === button_id ? null : button_id);
  }

  const boxes = type === null ? null :  <ModalBoxes type={type} chooseTypeBoxes={chooseTypeBoxes}/>

    return(
     <div className={s.home}>
           
           <Header />
           <Content chooseTypeBoxes={chooseTypeBoxes}/>

           {boxes}

      </div> 
    )
}

export default Home;
