import React, { useState, useEffect, useRef , useContext} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { SocketContext } from '../../../services/socket';
import s from './CaseOpener.module.scss';
import DropImg from './DropImg/DropImg';
import DropModal from '../DropModal/DropModal';
import InfoModal from '../InfoModal/InfoModal';

const CaseOpener = ({price_base,price_pin,id_case, updateStateScore}) => {

  const socket = useContext(SocketContext); 
  const [itemsDrop, setItemsDrop] = useState([]);

  const [typeOpen,setTypeOpen] = useState(0);
  const [info,setInfo] = useState('');
  
  const [pincode, setPincode] = useState(['', '', '', '']);
  const inputs = useRef([]);

  const containerRef = useRef(null); //Для получения ширины контейнера

  const [isAnimating, setIsAnimating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const openCase = (typeOpen) => {
    if (socket) {
      socket.emit('openingCase',pincode,typeOpen,id_case)
     }

  };


 const delayDropRef = useRef(null);

  useEffect(()=> {
    if (socket) {
        socket.on('resultOpeningCase', (data) => {
          setItemsDrop(data)
          if (!isAnimating) {
            setIsAnimating(true);
          }
          delayDropRef.current = setTimeout(() => { 
            setIsOpen(true)
            setIsAnimating(false)
        },11000)
        })
      
        socket.on('resultOpeningCaseError',(data) => {
          setInfo(data.type)
        })
    }
    return () => {
      if (socket) {
       socket.off('resultOpeningCase');
       socket.off('resultOpeningCaseError');
      }
     };
  },[])

	useEffect(() => {
    const handleClick = (event) => {
      if (isAnimating) {
        skipAnimation();
      }
    };
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isAnimating]);

  const skipAnimation  = () => {
    setIsOpen(true)
    setIsAnimating(false)
    if (delayDropRef.current) {
      clearTimeout(delayDropRef.current);
      delayDropRef.current = null;
    }
  }

  const handleChangePin = (index, value) => {
    const numericValue = value.replace(/[^0-9]/g, '');
    const newCode = [...pincode];
    newCode[index] = numericValue.substring(0, 1);
    setPincode(newCode);

    if (numericValue.length > 0 && index < 3) {
      setTimeout(() => {
        inputs.current[index + 1]?.focus();
      }, 0);
    } else if (value.length < pincode[index].length && index > 0) {
      setTimeout(() => {
        inputs.current[index - 1]?.focus();
      }, 0);
    }
  };

  const isInputDisabledPin = (index) => {
    return pincode[index-1] === '' && index > 0;
  };

   const changeStatusOpening = (i) => {
    setTypeOpen(i)
   }

   const closeModal = () => {
    setIsOpen(false)
    setTypeOpen(0)
    updateStateScore()
   }

   const closeInfoModal = () => {
    setInfo('')
    setPincode(['', '', '', ''])
    updateStateScore()
   }
  return (
    <div className={s.caseOpener}>
      {
        typeOpen == 0 && !isAnimating &&
        <div className={s.typeButtonBuy}>
          <div className={s.buttons}>
              <div className={s.buttonBuy} onClick={() =>  openCase(1)}>
                    Открыть сразу <span className={s.price}>{price_base} $PIG</span>
                </div>
                    <div className={s.buttonBuy2} onClick={()=> changeStatusOpening(1)}>
                        Открыть с ПИН-кодом  <span  className={s.price}>{price_pin} $PIG</span>
                  </div>
          </div>
        </div>
        
      }
      {
        typeOpen == 1 && !isAnimating &&
        <div className={s.pincodeForm}>
          <div className={s.headerPin}>Введите ПИН-код:</div>
          <div>
          {[0, 1, 2, 3].map((index) => (
              <input
                key={index}
                ref={(el) => (inputs.current[index] = el)}
                type="text"
                maxLength="1"
                onChange={(e) => handleChangePin(index, e.target.value)}
                value={pincode[index]}
                disabled={isInputDisabledPin(index)}
              
              />
            ))}
          </div>
          <div className={s.buttons}>
              <div className={s.checkPin} onClick={() =>  openCase(2)}>Проверить</div>
              <div className={s.checkPinBack} onClick={()=> changeStatusOpening(0)}>Отмена</div>
          </div>
           
      </div>
      }
      { 
          isOpen && <DropModal closeModal={closeModal} itemDrop={itemsDrop[25]}/>
      }
      {
          info != '' && <InfoModal info = {info} closeInfoModal={closeInfoModal}/>
      }
    
      <AnimatePresence initial={false}>
        {isAnimating && (
          <motion.div
            ref={containerRef}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '180px',
              position: 'relative'
             
            }}
            initial="initial"
            animate="animate"
            exit="exit"
          >
           <motion.div // Центральная полоса
                style={{
                    position: 'absolute',
                    height: '100%',
                    width: 2,
                    backgroundColor: '#00DDA3',
                    left: '50%',
                    transform: 'translateX(-50%)', // Центрируем полосу внутри родителя
                    zIndex: 1, // Устанавливаем z-index
            }}
            animate={{ left: isAnimating ? '50%' : '0vw', transition: { duration: 3 } }} // Анимация
        />

            {itemsDrop.map((item) => {

            
                return (
                  <DropImg item={item} />
                )
            }
         
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CaseOpener;
