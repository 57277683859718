import React,  { useState, useEffect, useContext } from "react";
import s from './DropModal.module.scss';
import ElementImg from "../PageCase/ElementImg/ElementImg";

const DropModal = ({itemDrop,closeModal}) => {

   
    return(
        <div className={s.dropModal}>
           <div className={s.container}>
                <div className={s.header}>{itemDrop.name}</div>
                <div className={s.gap}></div>
                <ElementImg imagePath={itemDrop.img} quality={itemDrop.quality} />
                <div className={s.button} onClick={() => closeModal()}>
                    Получить
                </div>
           </div>
        </div>
    )
}

export default DropModal;