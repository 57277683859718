import React , { useEffect, useState , useContext, useRef, useCallback } from "react";
import s from './Inventory.module.scss';
import { SocketContext } from "../../services/socket";
import ElementImg from "../Shop/PageCase/ElementImg/ElementImg";
import InfoInventory from "./InfoInventory/InfoInventory";

const Inventory = () => {

    const socket = useContext(SocketContext); 

    const [itemsInventory, setItemsInventory] =  useState([]) 
    const [infoDrop, setInfoDrop] = useState(null)

    useEffect(() => {
        const interval = setInterval(() => {
            if (socket) {
                clearInterval(interval);
                socket.emit('getInventory')
            }
        },100)
  
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        } 
    },[socket]);

    useEffect(()=> {
        if (socket) {
            socket.on('inventoryList', (data) => {
                setItemsInventory(data)
            })
        }
      })
      const closeModal = () => {
        setInfoDrop(null)
    }
    const openInfoModal = (item) => {
        setInfoDrop(item)
    }
      
    return(
     <div className={s.inventory}>
        <div className={s.friendsHeader}>
            Твой инвентарь
        </div>
        <div className={s.taskDesk}>Предметы полученные из сейфов будут отображаться здесь.</div>
        {infoDrop != null && <InfoInventory itemDrop={itemsInventory[infoDrop]} closeModal={closeModal} />}
         <div className={s.items}> 
         {
            itemsInventory.map((c) => {
                let imgCase 
                   if (c.quality == 'common') {imgCase = s.itemCommon}
                   if (c.quality == 'uncommon') {imgCase = s.itemUncommon} 
                   if (c.quality == 'rare') {imgCase = s.itemRare} 
                   if (c.quality == 'epic') {imgCase = s.itemEpic} 
                   if (c.quality == 'legend') {imgCase = s.itemLegendary} 
                return (
                    <div className={imgCase} onClick={()=>openInfoModal(c.id)} >
                        <ElementImg imagePath={c.img} />
                    </div>
                )
            })
        }
        
         </div>
      </div> 
    )
}

export default Inventory;



