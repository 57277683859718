import React , { useEffect, useState , useContext, useRef, useCallback } from "react";
import s from './Shop.module.scss';
import { SocketContext } from "../../services/socket";
import PageCase from "./PageCase/PageCase";
import {useDispatch,useSelector} from 'react-redux';

const Shop = () => {

    const gameScore = new Intl.NumberFormat('ru-RU').format(useSelector((state) => state.counter.score)) ;

    const [scoreState, setScoreState] = useState(gameScore)

    const [cases, setCases] =  useState([])
    const socket = useContext(SocketContext); 
    const [openCase, setOpenCase] = useState(0)
    const [openQuality, setOpenQuality] = useState(null)

    useEffect(() => {
        const interval = setInterval(() => {
            if (socket) {
                clearInterval(interval);
                socket.emit('getListCases')
            }
        },100)
  
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        } 
    },[socket]);

    useEffect(()=> {
        if (socket) {
            socket.on('listCases', (data) => {
                setCases(data)
            })
        }
      })

    const handleOpenCase = (id,quality) => {
        setOpenCase(id)
        setOpenQuality(quality)
    }

    const updateStateScore = () => {
        setScoreState(gameScore)
    }
      
    return(
     <div className={s.shop}>
        <div className={s.balance}>
            <div className={s.countScore} >{scoreState} <span>$PIG</span></div>
        </div>
   
    { openCase == 0 &&  
        <div>
            <div className={s.friendsHeader}>
                Магазин
            </div>
            <div className={s.items}> 
                {
                    cases.map((c) => {

                    let imgCase 
                    if (c.quality == 'common') {imgCase = s.imgCommon}
                    if (c.quality == 'uncommon') {imgCase = s.imgUncommon} 
                    if (c.quality == 'rare') {imgCase = s.imgRare} 
                    if (c.quality == 'epic') {imgCase = s.imgEpic} 
                    if (c.quality == 'legend') {imgCase = s.imgLegend} 
                        return (
                            <div className={s.item}>
                                <div className={imgCase}></div>
                                <div className={s.nameSafe}>{c.name_case}</div>
                                <div className={s.buttonBuy} onClick={() => handleOpenCase(c.id,c.quality)}>
                                    <div className={s.mode} >Перейти</div>
                                    <div className={s.toImg}></div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
        }
        {
            openCase != 0 && 
            <div>
                <div className={s.back} onClick={() => handleOpenCase(0,null)} >
                        <div className={s.backImg}></div>
                       
                </div>
                
                <PageCase id_case={openCase} quality={openQuality} updateStateScore={updateStateScore}/>

            </div>
            
        }
      </div> 
    )
}

export default Shop;



