import React, { useEffect, useState , useContext, useRef, useCallback } from "react";
import s from './AwardsReferral.module.scss';
import { SocketContext } from "../../../services/socket";
import { useDispatch, useSelector } from "react-redux";
import { updateReferralPoke } from "../../../store/reducers/counterReducers";
import Drop from "../Drop/Drop";

const AwardsReferral = () => {

    const lastRefState = useSelector((state) => state.counter.last_ref_received);
    const socket = useContext(SocketContext); 
    const [referrals,setReferrals] = useState(0)
    const dispatch = useDispatch(); 
    const [awards, setAwards] = useState([
     /*  {count_ref:1,
        common:null,
        uncommon:null,
        rare:null,
        epic:2,
        legendary:null},
            {count_ref:2,
            common:null,
            uncommon:null,
            rare:null,
            epic:2,
            legendary:3},
                {count_ref:3,
                common:null,
                uncommon:null,
                rare:null,
                epic:2,
                legendary:null},
                    {count_ref:4,
                    common:null,
                    uncommon:1,
                    rare:null,
                    epic:2,
                    legendary:null},
                        {count_ref:5,
                        common:null,
                        uncommon:2,
                        rare:null,
                        epic:2,
                        legendary:null},
                            {count_ref:6,
                            common:null,
                            uncommon:null,
                            rare:3,
                            epic:2,
                            legendary:null},
                                {count_ref:7,
                                common:null,
                                uncommon:null,
                                rare:null,
                                epic:2,
                                legendary:5}, */
    ]); 

    const [indexPoke, setIndexPoke] = useState(null); 
    const [countAwardPoke, setcountAwardPoke] = useState(null); 

    useEffect(() => {
        const interval = setInterval(() => {
            if (socket) {
                clearInterval(interval);
                socket.emit('getAwardsReferral')
                socket.emit('getListInviteReferral')
            }
        },100)

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        } 
    },[socket]);

    
    useEffect(()=> {
        if (socket) {
            socket.on('listAwardsReferral', (data) => {
                setAwards(data)
                console.log(awards)
            })

            socket.on('resReferralAward', (data) => {
                const payload = {
                    common: data.common,
                    uncommon: data.uncommon,
                    rare: data.rare,
                    epic: data.epic,
                    legendary: data.legendary,
                    last_ref_received: data.last_ref_received
                }
                setIndexPoke(data.indexTypePoke);
                setcountAwardPoke(data.countNewPoke);
                dispatch(updateReferralPoke(payload));
            })


        socket.on('listInviteReferral', (data) => {
            setReferrals(data.length)
        })
                    
        }
        
    })

    const scrollRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
  
    const startScrolling = (event) => {
        if (scrollRef.current.contains(event.target)) {
      setStartX(event.clientX);
      setIsDragging(true);
        }
    };
  
    const continueScrolling = (event) => {
      if (!isDragging) return;
      const deltaX = startX - event.clientX;
      scrollRef.current.scrollLeft += deltaX; 
      setStartX(event.clientX);
    };
  
    const stopScrolling = () => {
      setIsDragging(false);
    };
  
    useEffect(() => {
       
        scrollRef.current.addEventListener('mousedown', startScrolling);
        
        window.addEventListener('mousemove', continueScrolling);
        window.addEventListener('mouseup', stopScrolling);
  
      return () => {
        if (scrollRef.current) {
        scrollRef.current.removeEventListener('mousedown', startScrolling);
        }
        window.removeEventListener('mousemove', continueScrolling);
        window.removeEventListener('mouseup', stopScrolling);
      };
  }, [startScrolling, continueScrolling, stopScrolling]);

  const handleIconClick = () => { 
    if (socket) {
        socket.emit('getReferralAward')
    }
    
    };

    const typeDrop = [
        <div className={s.nameDrop}>Твой дроп: <span className={s.commonDrop}>Обычный мешочек </span><span>x{countAwardPoke}</span></div>,
        <div className={s.nameDrop}>Твой дроп: <span className={s.uncommonDrop}>Необычный мешочек </span><span>x{countAwardPoke}</span></div>,
        <div className={s.nameDrop}>Твой дроп: <span className={s.rareDrop}>Редкий мешочек </span><span>x{countAwardPoke}</span></div>,
        <div className={s.nameDrop}>Твой дроп: <span className={s.epicDrop}>Эпический мешочек </span><span>x{countAwardPoke}</span></div>,
        <div className={s.nameDrop}>Твой дроп: <span className={s.legendaryDrop}>Легендарный мешочек </span><span>x{countAwardPoke}</span></div>
    ]
    let contentDrop = indexPoke == null ? null : typeDrop[indexPoke]

    return(
        <div className={s.modal_streak}>
            <div className={s.headerStreak}>
                Награды за рефералов <span className={s.countAwards}>{referrals - lastRefState}</span>
            </div>
           
            <div className={s.listStreak} ref={scrollRef}  onMouseDown={startScrolling}>
               {
                    awards.map((award) => {
                       const common = award.common != null ? (<div className={s.common}>Обычный <span className={s.countAward}>x{award.common}</span></div>) : null ;
                       const uncommon = award.uncommon != null ? (<div className={s.uncommon}>Необычный <span className={s.countAward}>x{award.uncommon}</span></div>) : null ;
                       const rare = award.rare != null ? (<div className={s.rare}>Редкий <span className={s.countAward}>x{award.rare}</span ></div>) : null ;
                       const epic = award.epic != null ? (<div className={s.epic}>Эпический <span className={s.countAward}>x{award.epic}</span></div>) : null ;
                       const legendary = award.legendary != null ? (<div className={s.legendary}>Легендарный <span className={s.countAward}>x{award.legendary}</span></div>) : null ;
                       const received =
                       Number(lastRefState) >= Number(award.count_ref) 
                       ? <div className={s.received}>Получено</div> :
                       referrals - lastRefState > 0 && Number(lastRefState) + 1 == Number(award.count_ref) 
                       ? <div className={s.get} onClick={handleIconClick}>Забрать </div> 
                       : null;
                                
                       const styleActiveStreak = 
                                    award.count_ref == Number(referrals)    
                                    ? s.itemStreakActive :
                                    Number(lastRefState) >= Number(award.count_ref)
                                    ?  s.itemStreakReceived : s.itemStreak;
                  
                           return (
                            <div key={award.count_ref} className={styleActiveStreak}>
                                 <div className={s.day}>{award.count_ref} </div>
                
                                 {common}
                                 {uncommon}
                                 {rare}
                                 {epic}
                                 {legendary}
                                 {received}
                            </div>
                            
                           )
                           
                        }) 
                }
            </div>
            <div className={s.headerStreak}>
                {contentDrop}
            </div>
        </div>
    )
}

export default AwardsReferral;