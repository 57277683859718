import React,  { useState, useEffect, useContext } from "react";
import s from './InfoDrop.module.scss';
import ElementImg from "../ElementImg/ElementImg";

const InfoDrop = ({itemDrop,closeModal}) => {

    return(
        <div className={s.dropModal}>
           <div className={s.container}>
                <div className={s.header}>{itemDrop.name_product}</div>
                <div className={s.gap}></div>
                <ElementImg imagePath={itemDrop.img} quality={itemDrop.quality} />
                <div className={s.button} onClick={() => closeModal()}>
                    Закрыть
                </div>
           </div>
        </div>
    )
}

export default InfoDrop;