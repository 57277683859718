import React ,  { useState, useEffect, useContext } from "react";
import s from './BlockGame.module.scss'
import { useSelector } from "react-redux";

const BlockGame = ({chooseTypeBoxes}) => {


    const common = useSelector((state) => state.counter.common);
    const uncommon = useSelector((state) => state.counter.uncommon);
    const rare = useSelector((state) => state.counter.rare);
    const epic = useSelector((state) => state.counter.epic);
    const legendary = useSelector((state) => state.counter.legendary);

    return(
    
        <div className={s.blockgame}>   
                <div className={s.iconsBoxes}>
                    <div onClick={() => chooseTypeBoxes(0)} className={s.iconBox}>
                    <div className={s.img_0}></div><span className={s.countBox}>{common}</span>
                    </div>
                    <div onClick={() => chooseTypeBoxes(1)} className={s.iconBox} >
                        <div className={s.img_1}></div><span className={s.countBox}>{uncommon}</span>
                    </div>
                    <div onClick={() => chooseTypeBoxes(2)} className={s.iconBox}>
                        <div className={s.img_2}></div><span className={s.countBox}>{rare}</span>
                    </div>
                    <div onClick={() => chooseTypeBoxes(3)} className={s.iconBox}>
                        <div className={s.img_3}></div><span className={s.countBox}>{epic}</span>
                    </div>
                    <div onClick={() => chooseTypeBoxes(4)} className={s.iconBox}>
                        <div className={s.img_4}></div><span className={s.countBox}>{legendary}</span>
                    </div>
            </div>      
        </div>
    )
}

export default BlockGame;