import React, { useState, useEffect } from 'react';

function ElementImg(props) {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const imgPath = props.imagePath; 
    const img = require(`.././img/${imgPath}`);
    setImageUrl(img);
  }, [props.imagePath]);

  let shadow 
  if (props.quality == 'common') {shadow = '0.2vh 0.4vh 0.6vh 0.2vh rgba(148, 151, 153, 0.21)'}
  if (props.quality == 'uncommon') {shadow = '0.2vh 0.4vh 0.6vh 0.2vh rgba(23, 235, 34, 0.21)'} 
  if (props.quality == 'rare') {shadow = '0.2vh 0.4vh 0.6vh 0.2vh rgba(50, 105, 255, 0.21)'} 
  if (props.quality == 'epic') {shadow = '0.2vh 0.4vh 0.6vh 0.2vh rgba(163, 27, 241, 0.21)'} 
  if (props.quality == 'legend') {shadow = '0.2vh 0.4vh 0.6vh 0.2vh rgba(180, 192, 15, 0.21)'} 
  
  return (
    <img src={imageUrl} alt="Adidas Cap" style={{ width: '22vh', height: '17vh', boxShadow: shadow }}/>
  );
}
export default ElementImg;